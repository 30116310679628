/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import fromExponential from 'from-exponential';
import { saveAs } from 'file-saver';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';

import {
  readBucketStatus,
  readTableUsageBucket,
  readUsageBucketDataCards,
  readUsageBucketDates
} from '../endpoints';
import Box from '../../common/box/Box';
import Line from '../../common/line/Line';
import Text from '../../common/text/Text';
import Icon from '../../common/icon/Icon';
import TableComponent from '../../common/table-component';
import { colors } from '../../constant';
import { ENDPOINTS } from '../../../constants/endpoints';
import { LoaderWrap } from '../../common/loader/Loader';
import { CardSimple } from '../../common/card/CardSimple';
import { ExportModal } from '../../common/exportmodal/ExportModal';
import { localizedTextsMap } from '../helpers';
import { subtractDaysFromDate } from '../../common/range-picker';
import { exportElasticServiceDetailsFile } from '../../usage/endpoint';
import { formatStorageValue } from '../../../utils/format-storage-values.utils';
import { formatToYearMonthDayWithTimeZoneOffset } from '../../../utils/format.dates.utils';
import { allColumns } from '../../../utils/columns';

import { columns, renderStatus } from './helper';

const BucketUsage = () => {
  const { dateTime } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const bucketStatusActive = 'Active';
  const { setBucketUsageDates } = useStoreActions(actions => actions.usage);
  const { bucketUsageDates } = useStoreState(actions => actions.usage);
  const [exportLoading, setExportLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalSize, setTotalSize] = useState(10);
  const [syncDate, setSyncDate] = useState(
    dateTime ? moment(dateTime?.split('~')[1])?._d : null
  );
  const [showExportModal, setShowExportModal] = useState(false);
  const { theme } = useStoreState(actions => actions.theme);
  const { name, number } = useParams();
  const [displayLoader, setDisplayLoader] = useState(true);
  const [bucketStatus, setBucketStatus] = useState({
    status: null,
    bucketDeleteTime: null
  });
  const [dataCard, setDataCard] = useState({
    totalAPICalls: null,
    totalEgress: null,
    totalIngress: null,
    totalObjects: null,
    totalObjectsLabel: null,
    totalStorage: null,
    totalStorageLabel: null
  });

  const formatDate = date => {
    return `${moment(date).format('YYYY-MM-DD')} 00:00`;
  };

  useEffect(() => {
    if (dateTime) {
      setBucketUsageDates([
        moment(dateTime?.split('~')[0])?._d,
        moment(dateTime?.split('~')[1])
      ]);
    }
  }, [dateTime, setBucketUsageDates]);

  useEffect(() => {
    readBucketStatus(number)
      .then(([res]) => {
        setBucketStatus({
          status: res?.data?.data?.status,
          bucketDeleteTime: res?.data?.data?.bucketDeleteTime
        });
      })
      .catch(() => {
        // silent err
      });
  }, []);

  useEffect(() => {
    readUsageBucketDates(number)
      .then(([res]) => {
        if (res?.data?.data?.endTime) {
          setSyncDate(res?.data?.data.endTime);
          if (!dateTime && !bucketUsageDates[0]) {
            setBucketUsageDates([
              subtractDaysFromDate(
                new Date(formatDate(res?.data?.data?.endTime))
              ),
              subtractDaysFromDate(
                new Date(formatDate(res?.data?.data?.endTime)),
                0
              )
            ]);
          }
        } else if (!bucketUsageDates[0]) {
          setBucketUsageDates([moment().subtract(1, 'days')._d, moment()._d]);
        }
      })
      .catch(() => {
        // silent err
      });
  }, []);

  useEffect(() => {
    if (bucketUsageDates[0] !== null && bucketUsageDates[1] !== null) {
      readUsageBucketDataCards(
        ENDPOINTS.GET_USAGE_DATA_CARDS_BUCKET.replace(':bucketNumber', number)
          .replace(
            ':startTime:',
            formatToYearMonthDayWithTimeZoneOffset(bucketUsageDates[0])
          )
          .replace(
            ':endTime:',
            formatToYearMonthDayWithTimeZoneOffset(bucketUsageDates[1])
          )
      )
        .then(([res]) => {
          if (res?.status !== 200) {
            navigate('/buckets');
          }
          setDataCard({
            ...res?.data.data,
            dataCard: fromExponential(res?.data.data?.totalStorage)
          });
        })
        .catch(() => {
          // silent err
        });
    }
  }, [bucketUsageDates?.[1]]);

  useEffect(() => {
    if (bucketUsageDates[0] !== null && bucketUsageDates[1] !== null) {
      setDisplayLoader(true);
      readTableUsageBucket(
        ENDPOINTS.GET_USAGES_BUCKET.replace(':bucketNumber', number)
          .replace(':page:', page)
          .replace(':size:', size)
          .concat(
            bucketUsageDates[1]
              ? `&range=endTime=${formatToYearMonthDayWithTimeZoneOffset(
                  bucketUsageDates[0]
                )},${formatToYearMonthDayWithTimeZoneOffset(
                  bucketUsageDates[1]
                )}`
              : ''
          )
      ).then(([res]) => {
        const data = res?.data?.data?.items;
        setRows({ ...res?.data, data });
        setTotalSize(res?.data?.data?.total);
        setDisplayLoader(false);
      });
    }
  }, [page, size, bucketUsageDates[1]]);

  async function exportUsageBucket(event) {
    setExportLoading(true);
    let extension = event;
    if (event === 'excel') {
      extension = 'xlsx';
    }
    exportElasticServiceDetailsFile(
      ENDPOINTS.GET_USAGES_DETAILS_EXPORT_BUCKET(number, event).concat(
        bucketUsageDates[1]
          ? `range=endTime=${formatToYearMonthDayWithTimeZoneOffset(
              bucketUsageDates[0]
            )},${formatToYearMonthDayWithTimeZoneOffset(bucketUsageDates[1])}`
          : ''
      )
    ).then(([res, error]) => {
      if (error) {
        // silent err
      }
      setShowExportModal(false);
      setExportLoading(false);
      let fileName = res?.headers['file-name'];
      if (!fileName) {
        fileName = 'export';
      }
      saveAs(new Blob([res.data]), `${fileName}.${extension}`);
    });
  }

  function exportOnClick(event) {
    setShowExportModal(true);
  }

  const handleArrowClick = () => {
    navigate(-1);
  };

  const disabledDate = d => {
    return moment(d).isAfter(
      moment(subtractDaysFromDate(syncDate, 0) || bucketUsageDates[1]),
      'day'
    );
  };

  useEffect(() => {}, [dataCard]);

  return (
    <Box p="20px 30px" bgcolor={colors.softGray}>
      <Box
        onClick={handleArrowClick}
        display="inline-flex"
        alignitems="center"
        mb="20px"
        pointer="true"
      >
        <Icon icon="arrow_left"></Icon>
        <Text fontSize="18px" color={colors.black} fontWeight="500">
          {name}
        </Text>
        {bucketStatus?.status != null && (
          <Box ml="5px">
            {bucketStatus?.status && bucketStatus?.status === bucketStatusActive
              ? renderStatus('ACTIVE')
              : renderStatus(
                  'DELETED',
                  `${moment(bucketStatus.bucketDeleteTime).format(
                    'DD-MMM-yyyy, hh:mm A'
                  )}`
                )}
          </Box>
        )}
      </Box>

      <Box
        display="grid"
        gridcolumn="repeat(auto-fit, minmax(250px, 1fr) )"
        gap="20px"
        mb="30px"
      >
        <CardSimple
          icon
          style={{ width: '100%' }}
          formattedValue={
            typeof dataCard?.totalStorage === 'number'
              ? formatStorageValue(dataCard?.totalStorage)
              : '--'
          }
          statusNumber={dataCard?.totalStorageLabel}
          text={t('totalStorage')}
          measuringUnit="TB"
          message={t('totalStorageMessage')}
        >
          <Line
            color={theme.themes?.colorPrimary}
            absolute="true"
            t="0"
            l="0"
            borderRadius="4px 4px 0 0"
            borderWidth="3px"
          />
        </CardSimple>

        <CardSimple
          icon
          style={{ width: '100%' }}
          mainNumber={dataCard?.totalObjects
            ?.toFixed(1)
            ?.toLocaleString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          statusNumber={dataCard?.totalObjectsLabel}
          text={t('totalObjects')}
          message={t('totalObjectsMessage')}
        >
          <Line
            color={theme.themes?.colorPrimary}
            absolute="true"
            t="0"
            l="0"
            borderRadius="4px 4px 0 0"
            borderWidth="3px"
          />
        </CardSimple>

        <CardSimple
          style={{ width: '100%' }}
          mainNumber={dataCard?.totalAPICalls
            ?.toFixed(1)
            ?.toLocaleString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          text={t('apiCalls')}
          message={t('apiCallsMessage')}
        >
          <Line
            color={theme.themes?.colorPrimary}
            absolute="true"
            t="0"
            l="0"
            borderRadius="4px 4px 0 0"
            borderWidth="3px"
          />
        </CardSimple>

        <CardSimple
          style={{ width: '100%' }}
          formattedValue={
            typeof dataCard?.totalEgress === 'number'
              ? formatStorageValue(dataCard?.totalEgress)
              : '--'
          }
          text={t('egress')}
          message={t('egressMessage')}
        >
          <Line
            color={theme.themes?.colorPrimary}
            absolute="true"
            t="0"
            l="0"
            borderRadius="4px 4px 0 0"
            borderWidth="3px"
          />
        </CardSimple>

        <CardSimple
          style={{ width: '100%' }}
          formattedValue={
            typeof dataCard?.totalIngress === 'number'
              ? formatStorageValue(dataCard?.totalIngress)
              : '--'
          }
          text={t('ingress')}
          message={t('ingressMessage')}
        >
          <Line
            color={theme.themes?.colorPrimary}
            absolute="true"
            t="0"
            l="0"
            borderRadius="4px 4px 0 0"
            borderWidth="3px"
          />
        </CardSimple>
      </Box>

      <Box relative="true">
        <Box p="30px" bgcolor="white">
          <TableComponent
            localeText={localizedTextsMap}
            lastSync={syncDate}
            className="not-hover"
            size={size}
            page={page}
            columns={allColumns(columns, rows?.data)}
            rows={rows?.data}
            dateValue={bucketUsageDates}
            totalSize={totalSize}
            onPageChange={e => setPage(e)}
            onSizeChange={e => setSize(e)}
            dateOnChange={e => {
              setPage(0);
              setBucketUsageDates(e);
            }}
            exportOnClick={exportOnClick}
            cleanable={false}
            searchable={false}
            titleTable={true}
            hideFooter={!rows?.data?.length}
            disabledDate={bucketUsageDates => disabledDate(bucketUsageDates)}
            displayLoader={displayLoader}
            title={t('bucketUsageTitle')}
            tooltip={t('bucketUsageTooltip')}
            components={{
              LoadingOverlay: () => (
                <Box p="40px 20px">
                  <LoaderWrap display={true} />
                </Box>
              )
            }}
          />
        </Box>

        <ExportModal
          isLoading={exportLoading}
          open={showExportModal}
          close={() => setShowExportModal(false)}
          onSubmit={e => exportUsageBucket(e)}
        />
      </Box>
    </Box>
  );
};

export default BucketUsage;
