/* eslint-disable react/prop-types */
import React from 'react';
import fromExponential from 'from-exponential';
import styled, { useTheme } from 'styled-components';

import Box from '../common/box/Box';
import Text from '../common/text/Text';
import { colors } from '../constant';
import { renderCellExpand } from '../common/table/TableCellExpand';
import i18n from '../../i18n';

const RenderUsageBucket = props => {
  const theme = useTheme();
  const { name, bucket_num } = props.row;

  return (
    <Box maxwidth={`calc(${props.colDef?.computedWidth}px - 15px)`}>
      {renderCellExpand({
        value: name,
        width: props,
        fontWeight: '500',
        color: theme.themes?.colorPrimary
      })}
      <BucketName fontSize="12px" color={colors.gray20}>
        {bucket_num}
      </BucketName>
    </Box>
  );
};

const RenderUsageStatus = props => {
  const { status } = props.row;

  return (
    <Box>
      <Text
        fontWeight="500"
        fontSize="12px"
        borderradius="4px"
        bgcolor={status === 'Active' ? colors.green20 : colors.pink}
        color={status === 'Active' ? colors.darkGreen : colors.brown}
        p="2px 5px"
      >
        {status === 'Active' ? i18n.t('active') : i18n.t('deleted')}
      </Text>
    </Box>
  );
};

function formatNumber(value) {
  let newValue = fromExponential(value);
  newValue = Number(newValue).toFixed(3);
  if (newValue > 0 && newValue < 0.001) {
    return '< 0.001';
  }
  return newValue;
}

function formatNumbernotFixed(value) {
  let newValue = fromExponential(value);
  newValue = Number(newValue).toFixed();
  return newValue;
}

export const columns = [
  {
    field: 'name',
    headerName: i18n.t('bucket'),
    flex: 1,
    renderCell: RenderUsageBucket,
    sortable: false
  },
  {
    field: 'customRegion',
    flex: 1,
    headerName: i18n.t('region'),
    sortable: false,
    renderCell: params =>
      renderCellExpand({
        value: params.row.customRegion,
        width: params,
        color: colors.gray20
      })
  },
  {
    field: 'status',
    headerName: i18n.t('status'),
    renderCell: RenderUsageStatus,
    sortable: false
  },
  {
    field: 'activeStorage',
    flex: 1,
    headerName: i18n.t('activeStorageTB'),
    sortable: false,
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumber(params.row.activeStorage).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'deletedStorage',
    flex: 1,
    headerName: i18n.t('deletedStorageTB'),
    sortable: false,
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumber(params.row.deletedStorage).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'activeObjects',
    flex: 1,
    headerName: i18n.t('activeObjects'),
    sortable: false,
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumbernotFixed(params.row.activeObjects).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'deletedObjects',
    flex: 1,
    headerName: i18n.t('deletedObjects'),
    sortable: false,
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumbernotFixed(params.row.deletedObjects).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'apiCalls',
    flex: 1,
    headerName: i18n.t('apiCalls'),
    sortable: false,
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumbernotFixed(params.row.apiCalls).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'egress',
    minWidth: 120,
    flex: 1,
    headerName: i18n.t('egress'),
    sortable: false,
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumber(params.row.egress).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'ingress',
    minWidth: 120,
    flex: 1,
    headerName: i18n.t('ingress'),
    sortable: false,
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumber(params.row.ingress).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'storageWrote',
    flex: 1,
    headerName: i18n.t('storageWroteTB'),
    sortable: false,
    headerAlign: 'right',
    headerClassName: 'last-row',
    cellClassName: 'row-flex-end last-row',
    renderCell: params =>
      renderCellExpand({
        value: formatNumber(params.row.storageWrote).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'storageRead',
    flex: 1,
    headerName: i18n.t('storageReadTB'),
    sortable: false,
    headerAlign: 'right',
    headerClassName: 'last-row',
    cellClassName: 'row-flex-end last-row',
    renderCell: params =>
      renderCellExpand({
        value: formatNumber(params.row.storageRead).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  }
];

export const BucketName = styled(Text)(
  ({ theme }) => `
    // color: ${theme.themes?.colorPrimary};
`
);
