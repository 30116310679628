// Calculation of showing entire column texts on table
const measureTextWidth = (text, font = '16px Roboto') => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  return context.measureText(text).width;
};

export const calculateMinWidth = (column, rows) => {
  const paddingInline = 24;
  const cellWidths = rows?.map(row => {
    return measureTextWidth(row[column.field]?.toString() || '');
  });
  const cellWidth = Math.max(...cellWidths);
  const headerWidth = measureTextWidth(column.headerName || '');

  const calculatedWidth =
    cellWidth > headerWidth
      ? cellWidth + paddingInline
      : headerWidth + paddingInline;
  return Math.max(calculatedWidth);
};
