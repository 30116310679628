/* eslint-disable no-restricted-globals */
import * as Yup from 'yup';

import i18n from '../../../i18n';

export const initialValues = {
  username: '',
  password: ''
};

export const validationSchema = Yup.object({
  username: Yup.string()
    .required(i18n.t('usernameCannotBeEmpty'))
    .min(3, i18n.t('usernameMinLength')),
  password: Yup.string()
    .required(i18n.t('passwordRequired'))
    .min(6, i18n.t('passwordReqOnlyLengthChars'))
});

export const returnLanguage = language => {
  switch (language) {
    case 'en':
      localStorage.setItem(
        'language',
        JSON.stringify({
          value: 'en',
          label: 'English'
        })
      );
      break;
    case 'it':
      localStorage.setItem(
        'language',
        JSON.stringify({
          value: 'it',
          label: 'Italiano'
        })
      );
      break;
    case 'de':
      localStorage.setItem(
        'language',
        JSON.stringify({
          value: 'de',
          label: 'Deutsch'
        })
      );
      break;
    case 'pt':
      localStorage.setItem(
        'language',
        JSON.stringify({
          value: 'pt',
          label: 'Portuguese (BR)'
        })
      );
      break;
    default:
      break;
  }
};
