import React from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import Box from '../../common/box/Box';
import Text from '../../common/text/Text';
import fromExponential from 'from-exponential';
import { renderCellExpand } from '../../common/table/TableCellExpand';
import { colors } from '../../constant';
import Badge from '../../common/badge';
import i18n from '../../../i18n';

const formatDate = date => {
  return `${moment(date).format('YYYY-MM-DD')} 00:00`;
};

const RenderEndTime = props => {
  const endTime = formatDate(props?.row?.endTime);
  const dateFormat = 'DD-MMM-YYYY';
  let date = new Date(endTime);
  if (date.getTimezoneOffset() > 0) {
    date = moment(date).subtract(1, 'day');
  }

  return (
    <Box>
      <Text color="#525E68">{moment(date).format(dateFormat)}</Text>
    </Box>
  );
};

function formatNumber(value) {
  let newValue = fromExponential(value);
  newValue = Number(newValue).toFixed(3);
  if (newValue > 0 && newValue < 0.003) {
    return '< 0.001';
  }
  return newValue;
}

function formatNumbernotFixed(value) {
  let newValue = fromExponential(value);
  newValue = Number(newValue).toFixed();
  return newValue;
}
export const columns = [
  {
    field: 'endTime',
    headerName: i18n.t('recordDate'),
    flex: 1,
    sortable: false,
    renderCell: RenderEndTime,
    color: 'red'
  },
  {
    field: 'activeStorage',
    headerName: i18n.t('activeStorageTB'),
    flex: 1,
    sortable: false,
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumber(params.row.activeStorage).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'deletedStorage',
    headerName: i18n.t('deletedStorageTB'),
    flex: 1,
    sortable: false,
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumber(params.row.deletedStorage).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'activeObjects',
    headerName: i18n.t('activeObjects'),
    flex: 1,
    sortable: false,
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumbernotFixed(params.row.activeObjects).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'deletedObjects',
    headerName: i18n.t('deletedObjects'),
    flex: 1,
    sortable: false,
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumbernotFixed(params.row.deletedObjects).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'apiCalls',
    headerName: i18n.t('apiCalls'),
    flex: 1,
    sortable: false,
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumbernotFixed(params.row.apiCalls).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'egress',
    headerName: i18n.t('egressGB'),
    minWidth: 120,
    flex: 1,
    sortable: false,
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumber(params.row.egress).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'ingress',
    headerName: i18n.t('ingressGB'),
    flex: 1,
    minWidth: 120,
    sortable: false,
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumber(params.row.ingress).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'storageWrote',
    headerName: i18n.t('storageWrote'),
    flex: 1,
    sortable: false,
    headerAlign: 'right',
    cellClassName: 'row-flex-end',
    renderCell: params =>
      renderCellExpand({
        value: formatNumber(params.row.storageWrote).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  },
  {
    field: 'storageRead',
    headerName: i18n.t('storageRead'),
    flex: 1,
    sortable: false,
    headerAlign: 'right',
    headerClassName: 'last-row',
    cellClassName: 'row-flex-end last-row',
    renderCell: params =>
      renderCellExpand({
        value: formatNumber(params.row.storageRead).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        ),
        width: params,
        color: colors.gray20,
        customClassName: 'row-flex-end'
      })
  }
];

export const validationSchema = Yup.object().shape({
  fileName: Yup.string()
    .matches(/^[^/<>%]*$/, {
      message: i18n.t('cannotRenameObjectError'),
      excludeEmptyString: true
    })
    .label(i18n.t('File Name'))
    .required(i18n.t('fileNameRequired'))
});

export const renderStatus = (status, time) => {
  switch (status?.toUpperCase()) {
    case 'ACTIVE':
      return <Badge text={i18n.t('active')} />;
    case 'DELETED':
      return (
        <Badge
          backgroundColor={colors.pink}
          color={colors.brown}
          text={`${i18n.t('deleted')} ${time}`}
        />
      );
    default:
      return <Text>{i18n.t('noStatus')}</Text>;
  }
};
