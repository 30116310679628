/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { wait } from '@testing-library/user-event/dist/utils';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import Box from '../../common/box/Box';
import Title from '../../common/title/Title';
import { colors } from '../../constant';
import Button from '../../common/button/Button';
import Line from '../../common/line/Line';
import Text from '../../common/text/Text';
import Input, { ErrorMessage } from '../../common/input/Input';
import {
  deleteMemberUser,
  getMemberGroups,
  getMemberProfile,
  reinstateMember,
  updateMemberProfile
} from '../endpoints';
import TextAvatar from '../../header/TextAvatar';
import MemberDetailsChangePassword from './MemberDetailsChangePassword';
import { LoaderWrap } from '../../common/loader/Loader';
import hasPermission from '../../../security/Permission';
import DeleteModal from '../../common/modal/DeleteModal';
import CheckboxWrapper from '../../common/checkbox/CheckboxWrapper';
import { LabelInput } from '../../common/label/LabelInput';
import Icon from '../../common/icon/Icon';
import { Dropdown } from '../../common/select/Dropdown';
import { showToast } from '../../../hooks/show-toast';

import { MemberLabelRenderer } from './helpers';
import { ReinstateMemberButton, renderStatus } from './helpers';
import ReinstateMemberModal from './components/ReinstateMemberModal';

export default function MemberDetails() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { theme } = useStoreState(actions => actions.theme);
  const canReinstateMember = hasPermission('oem_members', 'reinstate');
  const canViewMemberPerm = hasPermission('oem_members', 'read');
  const canUpdateMemberPerm = hasPermission('oem_members', 'update');
  const canUpdateMemberActivePerm = hasPermission(
    'oem_members',
    'update_active'
  );
  const canDeleteMemberPerm = hasPermission('oem_members', 'delete');
  const navigate = useNavigate();
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userGroups, setUserGroups] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [active, setActive] = useState(false);
  const [image, setImage] = useState('');
  const [displayLoader, setDisplayLoader] = useState(false);
  const [showDeleteMembers, setShowDeleteMembers] = React.useState(false);
  const [memberToDelete, setMemberToDelete] = React.useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [response, setResponse] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [memberStatus, setMemberStatus] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(null);
  const [reinstateMemberModal, setReinstateMemberModal] = useState(false);
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
    reset
  } = useForm({ mode: 'onChange' });
  const [updateDisabled, setUpdateDisabled] = useState(true);
  const [profileData, setProfileData] = useState(true);
  const [isSSO, setIsSSO] = useState(false);

  const setData = data => {
    setFullName(data ? `${data?.firstName} ${data?.lastName}` : '');
    setMemberStatus(data?.state);
    setName(data?.firstName ?? '');
    setLastName(data?.lastName ?? '');
    setEmail(data?.email ?? '');
    setUserName(data?.username ?? '');
    setUserGroups(data?.groups ?? '');
    setUserRole(data?.groups.map(g => g.name).join(', ') ?? '');
    setSelectedRole(data?.groups?.[0].id ?? '');
    setActive(data?.active ?? false);
    setImage(data?.image ?? '');
    setRemainingTime(
      moment(data?.unBlockTime, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ') ?? null
    );
    setIsSSO(data.sso);
  };

  const handleRoleChange = event => {
    setSelectedRole(event.target.value);
  };

  /**
   * Get all Member Role
   */
  const getMemberRoles = () => {
    getMemberGroups().then(([res]) => {
      const arr = res.data.map(item => {
        return {
          ...item,
          name: t(item.name)
        };
      });
      setResponse(arr);
    });
  };

  const fetchMember = () => {
    setDisplayLoader(true);
    getMemberProfile(id).then(([res]) => {
      setDisplayLoader(false);
      setUpdateDisabled(false);

      if (res.success) {
        setData(res.data);
        setProfileData(res.data);
        reset();
      } else {
        if (res?.code || res?.Code !== 'ErrCcc0045')
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: false
            },
            'error'
          );
      }
    });
    getMemberRoles();
  };

  useEffect(() => {
    fetchMember();
  }, []);

  const onChangeName = event => {
    setName(event.target.value);
  };

  const onChangeLastName = event => {
    setLastName(event.target.value);
  };

  const onChangeEmail = event => {
    setEmail(event.target.value);
  };

  const onChangeUserName = event => {
    setUserName(event.target.value);
  };

  const onActiveChange = event => {
    setActive(event.target.checked);
  };

  const handleUpdate = () => {
    setButtonLoading('update');
    const newUserRole = canUpdateMemberPerm
      ? response.filter(role => role.id === selectedRole)
      : userGroups;
    const data = {
      id: +id,
      firstName: name,
      lastName,
      email,
      userName,
      groups: newUserRole,
      active
    };

    updateMemberProfile(id, data).then(([res]) => {
      if (res?.code || res?.Code !== 'ErrCcc0045')
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
      if (res.success) {
        setData(res.data);
        setProfileData(res.data);
      }
      setButtonLoading(null);
    });
  };

  const onDeleteClick = () => {
    setShowDeleteMembers(true);
    setMemberToDelete(id);
  };

  const handleDeleteMember = () => {
    // setDisplayLoader(true);
    setButtonLoading('delete');
    deleteMemberUser(memberToDelete).then(([res]) => {
      // setDisplayLoader(false);
      if (res.success) {
        setShowDeleteMembers(false);
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
        wait(1000).then(() => {
          navigate('../members');
        });
      } else {
        if (res?.code || res?.Code !== 'ErrCcc0045')
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: false
            },
            'error'
          );
      }
      setButtonLoading(null);
    });
  };

  const reinstateMemberHandler = () => {
    setButtonLoading('reinstate');
    reinstateMember(id).then(([res]) => {
      if (res.success) {
        setReinstateMemberModal(false);
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
        setReinstateMemberModal(false);
        fetchMember();
      } else {
        if (res?.code || res?.Code !== 'ErrCcc0045')
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: false
            },
            'error'
          );
      }
      setButtonLoading(null);
    });
  };

  const handleArrowClick = () => {
    navigate('/settings/members', { replace: true });
  };

  useEffect(() => {
    if (
      profileData?.active === getValues().active &&
      profileData?.email?.toLowerCase() === getValues().email?.toLowerCase() &&
      profileData?.firstName === getValues().firstName &&
      profileData?.lastName === getValues().lastName &&
      profileData?.username?.toLowerCase() ===
        getValues().userName?.toLowerCase() &&
      profileData?.groups?.[0].id === +getValues().userRole
    ) {
      setUpdateDisabled(true);
    } else {
      setUpdateDisabled(false);
    }
  });

  useEffect(() => {
    window.scrollTo({
      top: 0
    });
  }, []);

  const emailRegex = /^$|^(^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-][a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$)?$/;

  const targetTime = moment(remainingTime);
  const [currentTime, setCurrentTime] = useState(moment());
  const timeBetween = moment.duration(targetTime.diff(currentTime)).add(59000);
  const minutes = String(Math.floor(timeBetween.asMinutes()) % 60).padStart(
    2,
    '0'
  );
  const seconds = String(Math.floor(timeBetween.asSeconds()) % 60).padStart(
    2,
    '0'
  );

  useEffect(() => {
    if (memberStatus === 'SUSPENDED') {
      // Check if remaining time reaches zero
      if (timeBetween.asSeconds() <= 1) {
        setMemberStatus('ACTIVE');
      } else {
        const interval = setInterval(() => {
          setCurrentTime(moment());
        }, 1000);
        return () => clearInterval(interval);
      }
    }
  }, [currentTime, memberStatus]);

  return (
    <>
      <Box w="100%">
        <Box display={displayLoader ? 'none' : 'block'}>
          {canViewMemberPerm && (
            <Box>
              <form onSubmit={handleSubmit(handleUpdate)}>
                <Box bgcolor={colors.white} p="30px" mb="30px">
                  <Box
                    display="flex"
                    alignitems="center"
                    justifycontent="space-between"
                    width="100%"
                  >
                    <Box
                      display="flex"
                      alignitems="center"
                      gap="3px"
                      onClick={handleArrowClick}
                    >
                      <Icon
                        mr="10px"
                        icon="arrow_left"
                        width="7px"
                        height="15px"
                        fill="#0B293A"
                      ></Icon>
                      <Title
                        wordBreak="break-word"
                        m="0 10px 0 0"
                        maxwidth="83%"
                        cursor="pointer"
                        onClick={handleArrowClick}
                        fontSize="18px"
                        fontWeight="500"
                        color={colors.black}
                      >
                        {fullName}
                      </Title>
                      <Box mt="1px" mr="10px">
                        {memberStatus === 'SUSPENDED'
                          ? renderStatus(
                              `${memberStatus}-PROFILE`,
                              `${minutes}:${seconds}`
                            )
                          : renderStatus(memberStatus)}
                      </Box>
                    </Box>
                    {canUpdateMemberPerm && (
                      <Button
                        w="106px"
                        isLoading={buttonLoading === 'update'}
                        primary
                        type={'submit'}
                        disabled={!isValid || updateDisabled}
                      >
                        {t('update')}
                      </Button>
                    )}
                  </Box>
                  <Line m="20px 0 40px" />
                  <Box display="grid" gridcolumn="190px auto" columngap="30px">
                    <Box>
                      <ProfileImg
                        src={image}
                        display={image && image.length > 0 ? 'initial' : 'none'}
                      ></ProfileImg>
                      <TextAvatar
                        bgColor={colors.softGray}
                        textColor={theme.themes?.primary}
                        size={190}
                        showTextAvatar={!image || image.length === 0}
                        round="0"
                        name={name}
                        lastName={lastName}
                      />
                      <Box
                        bgcolor={colors.softGray}
                        center="true"
                        minheightsize="40px"
                        borderradius="4px"
                        mt="16px"
                      >
                        <CheckboxWrapper cursor={isSSO && 'not-allowed'}>
                          <input
                            disabled={isSSO}
                            type="checkbox"
                            id="active-input"
                            checked={active}
                            readOnly={!canUpdateMemberActivePerm}
                            {...register('active', {
                              onChange: onActiveChange
                            })}
                          />
                          <LabelInput
                            fontSize="14px"
                            id="active-label"
                            htmlFor="active-input"
                          >
                            {t('active')}
                          </LabelInput>
                        </CheckboxWrapper>
                      </Box>
                      {(memberStatus === 'SUSPENDED' ||
                        memberStatus === 'LOCKED') &&
                        canReinstateMember && (
                          <ReinstateMemberButton
                            onClick={() => setReinstateMemberModal(true)}
                            color={'white'}
                            backgroundColor={theme.themes?.colorPrimary}
                          >
                            {t('reinstateMember')}
                          </ReinstateMemberButton>
                        )}
                    </Box>
                    <div>
                      <div
                        className={`input-wrapper ${
                          isSSO ? 'input-wrapper-disabled' : ''
                        }`}
                      >
                        <Input
                          disabled={isSSO}
                          size="0"
                          width="100%"
                          fontSize="14px"
                          name="firstName"
                          value={name}
                          marginBottom="20px"
                          className={errors.firstName ? 'not-valid' : ''}
                          {...register('firstName', {
                            onChange: onChangeName,
                            minLength: 2,
                            maxLength: 50,
                            required: true,
                            pattern: /^\S.*$/g
                          })}
                        />
                        <label
                          htmlFor="firstName"
                          className={name.length > 0 ? 'filledField' : ''}
                        >
                          {t('firstName')}*
                        </label>
                        {errors.firstName?.type === 'minLength' && (
                          <ErrorMessage className="error-message--wrap">
                            {t('firstNameMinLengthError')}
                          </ErrorMessage>
                        )}
                        {errors.firstName?.type === 'maxLength' && (
                          <ErrorMessage className="error-message--wrap">
                            {t('firstNameMaxLengthError')}
                          </ErrorMessage>
                        )}
                        {errors.firstName?.type === 'required' && (
                          <ErrorMessage className="error-message--wrap">
                            {t('firstNameRequired')}
                          </ErrorMessage>
                        )}
                        {errors.firstName?.type === 'pattern' && (
                          <ErrorMessage className="error-message--wrap">
                            {t('firstNamePattern')}
                          </ErrorMessage>
                        )}
                      </div>
                      <div
                        className={`input-wrapper ${
                          isSSO ? 'input-wrapper-disabled' : ''
                        }`}
                      >
                        <Input
                          disabled={isSSO}
                          size="0"
                          width="100%"
                          fontSize="14px"
                          name="lastName"
                          marginBottom="20px"
                          value={lastName}
                          className={errors.lastName ? 'not-valid' : ''}
                          {...register('lastName', {
                            onChange: onChangeLastName,
                            minLength: 2,
                            maxLength: 50,
                            required: true,
                            pattern: /^\S.*$/g
                          })}
                        />
                        <label
                          htmlFor="lastName"
                          className={lastName.length > 0 ? 'filledField' : ''}
                        >
                          {t('lastName')}*
                        </label>
                        {errors.lastName?.type === 'minLength' && (
                          <ErrorMessage className="error-message--wrap">
                            {t('lastNameMinLengthError')}
                          </ErrorMessage>
                        )}
                        {errors.lastName?.type === 'maxLength' && (
                          <ErrorMessage className="error-message--wrap">
                            {t('lastNameMaxLengthError')}
                          </ErrorMessage>
                        )}
                        {errors.lastName?.type === 'required' && (
                          <ErrorMessage className="error-message--wrap">
                            {t('lastNameRequired')}
                          </ErrorMessage>
                        )}
                        {errors.lastName?.type === 'pattern' && (
                          <ErrorMessage className="error-message--wrap">
                            {t('lastNamePattern')}
                          </ErrorMessage>
                        )}
                      </div>
                      <div
                        className={`input-wrapper ${
                          isSSO ? 'input-wrapper-disabled' : ''
                        }`}
                      >
                        <Input
                          disabled={isSSO}
                          size="0"
                          width="100%"
                          fontSize="14px"
                          name="email"
                          marginBottom="20px"
                          value={email}
                          className={errors.email ? 'not-valid' : ''}
                          {...register('email', {
                            onChange: onChangeEmail,
                            required: true,
                            pattern: { value: emailRegex }
                          })}
                        />
                        <label
                          htmlFor="email"
                          className={email.length > 0 ? 'filledField' : ''}
                        >
                          {t('email')}*
                        </label>
                        {errors.email?.type === 'required' && (
                          <ErrorMessage className="error-message--wrap">
                            {t('emailRequired')}
                          </ErrorMessage>
                        )}
                        {errors.email?.type === 'pattern' && (
                          <ErrorMessage className="error-message--wrap">
                            {t('emailPattern')}
                          </ErrorMessage>
                        )}
                      </div>
                      <div
                        className={`input-wrapper ${
                          isSSO ? 'input-wrapper-disabled' : ''
                        }`}
                      >
                        <Input
                          disabled={isSSO}
                          size="0"
                          width="100%"
                          fontSize="14px"
                          name="userName"
                          marginBottom="20px"
                          value={userName}
                          className={errors.userName ? 'not-valid' : ''}
                          {...register('userName', {
                            onChange: onChangeUserName,
                            minLength: 3,
                            maxLength: 200,
                            required: true,
                            pattern: /^\S.*$/g
                          })}
                        />
                        <label
                          htmlFor="userName"
                          className={userName.length > 0 ? 'filledField' : ''}
                        >
                          {t('userName')}*
                        </label>
                        {errors.userName?.type === 'minLength' && (
                          <ErrorMessage className="error-message--wrap">
                            {t('usernameMinLengthError')}
                          </ErrorMessage>
                        )}
                        {errors.userName?.type === 'maxLength' && (
                          <ErrorMessage className="error-message--wrap">
                            {t('usernameMaxLengthError')}
                          </ErrorMessage>
                        )}
                        {errors.userName?.type === 'required' && (
                          <ErrorMessage className="error-message--wrap">
                            {t('usernameRequired')}
                          </ErrorMessage>
                        )}
                        {errors.userName?.type === 'pattern' && (
                          <ErrorMessage className="error-message--wrap">
                            {t('usernamePattern')}
                          </ErrorMessage>
                        )}
                      </div>
                      {canUpdateMemberPerm && !isSSO ? (
                        <FormControl fullWidth>
                          {MemberLabelRenderer()}
                          <Dropdown
                            onMouseEnter={() => null}
                            open={isDropDownOpen}
                            onOpen={() => setIsDropDownOpen(true)}
                            onClose={() => {
                              setIsDropDownOpen(false);
                            }}
                            id="userRole"
                            label={t('memberRole')}
                            labelId="storeLogsLabel"
                            fullWidth
                            variant="outlined"
                            name="userRole"
                            defaultValue={selectedRole}
                            value={selectedRole}
                            className={errors.storeLogs ? 'not-valid' : ''}
                            {...register('userRole', {
                              onChange: handleRoleChange,
                              required: true
                            })}
                            MenuProps={{
                              getcontentanchorel: null,
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left'
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left'
                              },
                              PaperProps: { sx: { maxHeight: 230 } }
                            }}
                          >
                            {response &&
                              response.map(b => (
                                <MenuItem key={b.id} value={b.id}>
                                  <Box
                                    w="100%"
                                    display="flex"
                                    p="0"
                                    lineheight="40px"
                                  >
                                    <Text fontSize="14px" fontWeight="400">
                                      {b.name}
                                    </Text>
                                  </Box>
                                </MenuItem>
                              ))}
                          </Dropdown>
                          {errors.userRole?.type === 'required' && (
                            <ErrorMessage>
                              {t('selectedRoleRequired')}
                            </ErrorMessage>
                          )}
                        </FormControl>
                      ) : (
                        <div
                          className={`input-wrapper ${
                            isSSO ? 'input-wrapper-disabled' : ''
                          }`}
                        >
                          <Input
                            disabled={isSSO}
                            size="0"
                            width="100%"
                            fontSize="14px"
                            name="userRole"
                            marginBottom="0"
                            value={userRole}
                            readOnly
                          />
                          {MemberLabelRenderer(userRole.length > 0)}
                        </div>
                      )}
                    </div>
                  </Box>
                </Box>
              </form>
              <MemberDetailsChangePassword id={id} isSSO={isSSO} />
              {canDeleteMemberPerm && (
                <Box
                  bgcolor={colors.white}
                  p="10px 30px"
                  borderradius="4px"
                  display="flex"
                  alignitems="center"
                  justifycontent="space-between"
                  mt="30px"
                >
                  <Title fontSize="18px" fontWeight="500" color="#0B293A">
                    {t('deleteMember')}
                  </Title>
                  <Button
                    disabled={isSSO}
                    bgcolor={colors.softGray}
                    color={colors.gray20}
                    bordercolor="transparent"
                    className="delete"
                    onClick={onDeleteClick}
                  >
                    {t('delete')}
                  </Button>
                </Box>
              )}
              <DeleteModal
                isLoading={buttonLoading === 'delete'}
                open={showDeleteMembers}
                close={() => setShowDeleteMembers(false)}
                onSubmit={e => handleDeleteMember(e)}
                name={t('member')}
                deleteText={t('deleteMemberConfirmation')}
                text={t('deleteUpperCase')}
              />
              <ReinstateMemberModal
                isLoading={buttonLoading === 'reinstate'}
                handleSubmit={() => reinstateMemberHandler()}
                open={reinstateMemberModal}
                close={() => setReinstateMemberModal(false)}
              />
            </Box>
          )}
        </Box>
        <Box
          display={displayLoader ? 'flex' : 'none'}
          relative="true"
          minheightsize="450px"
          alignitems="center"
        >
          <LoaderWrap display={true} />
        </Box>
      </Box>
    </>
  );
}

const ProfileImg = styled.img`
  width: 190px;
  height: 190px;
  display: ${props => props.display ?? 'white'};
`;
