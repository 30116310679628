import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonToolbar, Checkbox, Tag } from 'rsuite';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Dropdown from 'react-multilevel-dropdown';

import Box from '../box/Box';
import Text from '../text/Text';
import Icon from '../icon/Icon';
import Input from '../input/Input';
import { LoaderWrap } from '../loader/Loader';
import { colors } from '../../constant';
import { readFiltersWith } from '../../usage/endpoint';

import { translateFilterName } from './filter-translations';
import DropdownButton from './DropdownButton';

export default function MultiFilter({
  filters,
  filterOnChange,
  filterWith,
  selectedFilters,
  setSelectedFilters
}) {
  const { t } = useTranslation();
  const [dynamicItems, setDynamicItems] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const isFilteringOnUsageDetails = filterWith?.startsWith(
    'usage_details/filters?filter_with='
  );

  const handleFilterSelect = value => {
    const index = selectedFilters.findIndex(
      filter => filter.key === value.key && filter.value === value.value
    );
    if (index === -1) {
      setSelectedFilters(selectedFilters => [...selectedFilters, value]);
      filterOnChange([...selectedFilters, value]);
    } else {
      const updatedFilters = [...selectedFilters];
      updatedFilters.splice(index, 1);
      setSelectedFilters(updatedFilters);
      filterOnChange(updatedFilters);
    }
  };

  const handleChipClose = value => {
    setSelectedFilters(selectedFilters =>
      selectedFilters.filter(filter => filter !== value)
    );
    filterOnChange(selectedFilters.filter(filter => filter !== value));
  };

  const renderSelectedFilter = filter => {
    return (
      <Tag key={filter.value} closable onClose={() => handleChipClose(filter)}>
        {translateFilterName(filter.value)}
      </Tag>
    );
  };

  const getFiltersBy = key => {
    setSearchKeyword('');
    setDynamicItems(null);
    readFiltersWith(filterWith || '/filters', key).then(([res]) => {
      setDynamicItems(res?.data?.data?.items || res?.data?.items || []);
    });
  };

  const renderFilterItem = (item, filter) => {
    const index = selectedFilters.findIndex(
      selectedFilter =>
        selectedFilter.key === filter.key && selectedFilter.value === item.key
    );
    const isChecked = index !== -1;

    return (
      <Dropdown.Item
        key={item.key}
        onClick={() => handleFilterSelect({ key: filter.key, value: item.key })}
      >
        <Checkbox key={item.key} checked={isChecked}>
          {translateFilterName(item.key)}
        </Checkbox>
      </Dropdown.Item>
    );
  };

  const renderFilter = filter => {
    const items = [];
    const filteredItems =
      dynamicItems?.length > 0
        ? dynamicItems?.filter(item =>
            item.key.toLowerCase().includes(searchKeyword.toLowerCase())
          )
        : null;

    if (filteredItems === null || filteredItems?.length === 0) {
      items.push(
        <Text
          fontSize="14px"
          textalign="center"
          mt="10px"
          p="10px 0"
          color={colors.bodyColor}
          key="no-results"
        >
          {t('thereIsCurrentlyNoData')}
        </Text>
      );
    } else {
      filteredItems.forEach(item => {
        items.push(renderFilterItem(item, filter));
      });
    }

    const dropdownMenu = title => (
      <Box>
        <Text
          fontSize="14px"
          fontWeight="400"
          color={colors.bodyColor}
          texttransform="capitalize"
          p="15px"
        >
          {translateFilterName(title)}
          <Icon icon="arrow_right" width="8px" height="8px" ml="30px"></Icon>
        </Text>
      </Box>
    );

    return (
      <Dropdown
        title={dropdownMenu(filter.key)}
        wrapperClassName="dropdown-wrapper"
        menuClassName="dropdown-menu-wrapper"
        key={filter.key}
        position="left"
        onClick={() =>
          getFiltersBy(
            isFilteringOnUsageDetails && filter.key === 'region'
              ? 'customRegion'
              : filter.key
          )
        }
      >
        {dynamicItems === null ? (
          <Box p="20px 0">
            <LoaderWrap width="65px" display={true} />
          </Box>
        ) : (
          <>
            {items.length > 10 ? (
              <Box p="0 10px">
                <Input
                  type="text"
                  placeholder="Search..."
                  value={searchKeyword}
                  onChange={e => setSearchKeyword(e.target.value)}
                />
              </Box>
            ) : (
              ''
            )}

            {items}
          </>
        )}
      </Dropdown>
    );
  };

  const clearAllFilter = () => {
    setSelectedFilters([]);
    filterOnChange([]);
  };

  return (
    <FilterBox>
      <Dropdown
        title={<DropdownButton />}
        wrapperClassName="button-no-padd"
        position="right"
      >
        {filters.map(filter => renderFilter(filter))}
      </Dropdown>

      {selectedFilters.length > 0 && (
        <>
          <Box
            border="1px solid"
            bordercolor={colors.lightGray}
            borderradius="4px"
            w="100%"
            minwidth="450px"
            maxwidth="450px"
            p="5px 10px 0"
            minheightsize="40px"
            maxheightsize="70px"
            display="flex"
            wrap="wrap"
            overflowy="auto"
            alignitems="center"
          >
            {selectedFilters.map(filter => renderSelectedFilter(filter))}
          </Box>

          <Box bgcolor={colors.lightGray} p="13px 10px" borderradius="4px">
            <Icon icon="clearFilter" onClick={() => clearAllFilter()} />
          </Box>
        </>
      )}
    </FilterBox>
  );
}

export const FilterBox = styled(ButtonToolbar)`
  button {
    min-width: 130px;
    padding: 10px;
    text-align: left;
  }
`;

MultiFilter.propTypes = {
  filters: PropTypes.array.isRequired,
  filterOnChange: PropTypes.func.isRequired,
  filterWith: PropTypes.string.isRequired,
  selectedFilters: PropTypes.array.isRequired,
  setSelectedFilters: PropTypes.func.isRequired
};
