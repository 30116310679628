import { calculateMinWidth } from './measure-text-width';

export function allColumns(columns, rows) {
  return columns.map(col => {
    if (!rows) return col;
    return {
      ...col,
      minWidth: col.minWidth || calculateMinWidth(col, rows)
    };
  });
}
