import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '../../../../common/box/Box';
import Text from '../../../../common/text/Text';
import Label from '../../../../common/label/Label';
import Icon from '../../../../common/icon/Icon';
import { colors } from '../../../../constant';
import ObjectLockingOptions from '../ObjectLockingOptions';
import Button from '../../../../common/button/Button';
import { useFormik } from 'formik';
import { initialValues, ValidationSchema } from './helpers';
import { enableObjectLockingByName } from '../../../endpoints';
import { useParams } from 'react-router';
import moment from 'moment-timezone';
import { useStoreState } from 'easy-peasy';
import { formatDateWithUtc } from '../../../../../utils/format.dates.utils';
import { showToast } from '../../../../../hooks/show-toast';

const ObjectLocking = props => {
  const {
    response,
    isEditing,
    setIsEditing,
    editingLocked,
    setEditingLocked,
    open,
    fileNameDir,
    setResponse,
    setDeleteClicked,
    canUpdateFilePerm
  } = props;

  const { t } = useTranslation();
  const { theme } = useStoreState(actions => actions.theme);
  const { name: objName } = useParams();
  const [locking, setLocking] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = fieldValues => {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setIsLoading(true);
    const payload = {
      mode:
        fieldValues?.selectedMode === null
          ? null
          : fieldValues?.selectedMode?.toUpperCase(),
      retainUntilDate: moment(fieldValues?.retainDate)
        .tz(browserTimezone)
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      versionId: response?.versionId
    };

    enableObjectLockingByName(objName, fileNameDir, payload).then(([res]) => {
      if (res.success) {
        const message =
          res.data.mode === ''
            ? t(res.code)
            : t(res.code)
                .concat(` ${t('until')} `)
                .concat(
                  moment(
                    new Date(res.data?.retainUntilDate),
                    'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
                  ).format('DD-MMM-YYYY hh:mm A')
                );
        showToast(
          { message: message, success: res?.success, params: res?.params },
          res?.success ? 'success' : 'error'
        );
        setResponse({ ...response, locking: res.data });
        if (payload?.mode === null) {
          setLocking({ locking: false });
          setResponse({ ...response, locking: false });
        } else setLocking(res?.data);
        setEditingLocked(false);
        setIsEditing(false);
      } else {
        if (res?.code || res?.Code !== 'ErrCcc0045')
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: false
            },
            'error'
          );
      }
      setIsLoading(false);
    });
  };
  const formik = {
    ...useFormik({
      validationSchema: ValidationSchema,
      initialValues: response?.locking?.locked
        ? {
            selectedMode: response?.locking?.mode,
            retainDate: moment.utc(response?.locking?.retainUntilDate).toDate(),
            versionId: response?.versionId
          }
        : initialValues,
      enableReinitialize: true,
      onSubmit: fieldValues => onSubmit(fieldValues)
    })
  };

  useEffect(() => {
    return () => {
      setEditingLocked(false);
      setIsEditing(false);
      setDeleteClicked(false);
      formik.resetForm();
    };
  }, [open, setIsEditing, setEditingLocked]);

  useEffect(() => {
    setLocking(response?.locking);
  }, [response?.locking]);

  const { handleSubmit } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <Box mb="30px">
        <Box display={'flex'} justifycontent={'space-between'}>
          <Box display={'flex'}>
            <Label fontWeight="500">{t('objectLocking')}</Label>
            {locking?.locked && (
              <Icon ml={'7px'} width={'15px'} icon={'green_checkmark'} />
            )}
          </Box>
          {!locking?.locked && !editingLocked ? (
            <Label
              onClick={
                response?.canLockObject && canUpdateFilePerm
                  ? () => setEditingLocked(true)
                  : null
              }
              cursor={
                response?.canLockObject && canUpdateFilePerm
                  ? 'pointer'
                  : 'not-allowed'
              }
              color={
                response?.canLockObject && canUpdateFilePerm
                  ? theme?.themes?.colorPrimary
                  : colors?.gray
              }
              fontWeight="500"
            >
              {t('enable')}
            </Label>
          ) : !isEditing && !editingLocked ? (
            <Label
              onClick={
                response?.canLockObject && canUpdateFilePerm
                  ? () => setIsEditing(true)
                  : null
              }
              cursor={
                response?.canLockObject && canUpdateFilePerm
                  ? 'pointer'
                  : 'not-allowed'
              }
              color={
                response?.canLockObject && canUpdateFilePerm
                  ? theme?.themes?.colorPrimary
                  : colors?.gray
              }
              fontWeight="500"
            >
              {t('edit')}
            </Label>
          ) : (
            <> </>
          )}
        </Box>
        {locking?.locked && (
          <Box>
            <Text fontSize="14px">
              {t('mode')}: {t(locking?.mode) || ''}
            </Text>
            <Text fontSize="14px">
              {t('retainUntil')}:{' '}
              {locking?.retainUntilDate
                ? formatDateWithUtc(locking?.retainUntilDate)
                : ''}{' '}
            </Text>
          </Box>
        )}
        {(isEditing || editingLocked) && (
          <>
            <ObjectLockingOptions
              alreadySelectedDate={locking?.retainUntilDate}
              alreadySelectedMode={locking?.mode}
              locked={response.locking?.locked}
              formik={formik}
            />
            <Box borderTop display={'flex'} justifycontent="center" mt={'40px'}>
              <Button
                onClick={() => {
                  setIsEditing(false);
                  setEditingLocked(false);
                  formik.setFieldValue('selectedMode', locking?.mode);
                }}
                type={'button'}
                className="cancel"
                mr="20px"
              >
                {t('cancel')}
              </Button>
              <Button
                isLoading={isLoading}
                onClick={handleSubmit}
                type={'submit'}
                primary
                id={'applyButton'}
                disabled={
                  (!formik?.dirty && response?.locking?.locked) ||
                  formik?.values?.selectedMode?.length === 0
                }
              >
                {t('apply')}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </form>
  );
};
export default ObjectLocking;
