import React from 'react';
import { useTranslation } from 'react-i18next';
import { allColumns } from '../../../../utils/columns';
import { Table } from '../../table/Table';
import Box from '../../box/Box';
import { LoaderWrap } from '../../loader/Loader';

const TableWrapper = ({
  size = 10,
  page = 1,
  totalSize = 10,
  hideFooter,
  onPageChange,
  onSizeChange,
  rows,
  columns,
  onRowClick,
  components,
  displayLoader,
  className
}) => {
  const { t } = useTranslation();

  if (displayLoader && !components?.LoadingOverlay)
    return (
      <Box p="20px 0">
        <LoaderWrap display={true} />
      </Box>
    );

  return (
    <Table
      disableColumnFilter={false}
      disableColumnMenu={true}
      disableColumnSelector={true}
      autoHeight
      rows={rows?.length > 0 ? [...rows] : []}
      columns={allColumns(columns, rows?.length > 0 ? [...rows] : [])}
      page={page}
      pageSize={size}
      rowsPerPageOptions={[10, 20, 100]}
      experimentalFeatures={{ newEditingApi: true }}
      DataFormatString="{} {0:dd, MMM, yyyy}"
      getRowId={row => row?.id}
      pagination
      paginationMode="server"
      hideFooter={hideFooter}
      onPageChange={onPageChange}
      onPageSizeChange={onSizeChange}
      rowCount={totalSize}
      onRowClick={onRowClick}
      columnMenuFilterIcon
      components={components}
      loading={displayLoader}
      className={className}
      labelRowsPerPage={t('active')}
    />
  );
};

export default TableWrapper;
