import i18n from '../../i18n';

export const localizedTextsMap = {
  columnMenuUnsort: i18n.t('unsort'),
  columnMenuSortAsc: i18n.t('columnMenuSortAsc'),
  columnMenuSortDesc: i18n.t('columnMenuSortDesc'),
  columnMenuFilter: i18n.t('columnMenuFilter'),
  columnMenuHideColumn: i18n.t('columnMenuHideColumn'),
  columnMenuShowColumns: i18n.t('columnMenuShowColumns'),
  filterOperatorContains: i18n.t('filterOperatorContains'),
  filterOperatorEquals: i18n.t('filterOperatorEquals'),
  filterOperatorStartsWith: i18n.t('filterOperatorStartsWith'),
  filterOperatorEndsWith: i18n.t('filterOperatorEndsWith'),
  filterOperatorIs: i18n.t('filterOperatorIs'),
  filterOperatorNot: i18n.t('filterOperatorNot'),
  filterOperatorAfter: i18n.t('filterOperatorAfter'),
  filterOperatorOnOrAfter: i18n.t('filterOperatorOnOrAfter'),
  filterOperatorBefore: i18n.t('filterOperatorBefore'),
  filterOperatorOnOrBefore: i18n.t('filterOperatorOnOrBefore'),
  filterOperatorIsEmpty: i18n.t('filterOperatorIsEmpty'),
  filterOperatorIsNotEmpty: i18n.t('filterOperatorIsNotEmpty'),
  filterOperatorIsAnyOf: i18n.t('filterOperatorIsAnyOf'),
  filterPanelInputLabel: i18n.t('value'),
  filterPanelInputPlaceholder: i18n.t('filterValue'),
  filterPanelColumns: i18n.t('columns'),
  columnsPanelTextFieldLabel: i18n.t('findColumn'),
  columnsPanelTextFieldPlaceholder: i18n.t('columnTitle'),
  filterPanelOperators: i18n.t('operator'),
  columnsPanelHideAllButton: i18n.t('hideAll'),
  columnsPanelShowAllButton: i18n.t('showAll'),
  columnHeaderSortIconLabel: i18n.t('sort'),
  checkboxSelectionHeaderName: i18n.t('checkboxSelectionHeaderName')
};

export const getFlagUrl = region => {
  switch (region) {
    case 'us-west-1':
      return 'US';
    case 'us-east-1':
      return 'US';
    case 'us-east-2':
      return 'US';
    case 'us-central-1':
      return 'US';
    case 'us-east-3':
      return 'US';
    case 'ca-central-1':
      return 'CA';
    case 'eu-west-1':
      return 'UK';
    case 'eu-west-2':
      return 'FR';
    case 'eu-central-1':
      return 'NL';
    case 'eu-central-2':
      return 'DE';
    case 'ap-northeast-1':
      return 'JP';
    case 'ap-northeast-2':
      return 'JP';
    case 'ap-southeast-2':
      return 'AU';
    case 'ap-southeast-1':
      return 'SG';
    default:
      return 'US';
  }
};
