/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-children-prop */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import styled from 'styled-components';

import {
  deleteBucket,
  enableBucketVersioning,
  enableObjectLocking,
  getAllBucketsNames,
  getBucketProperties,
  updateBucketProperties,
  updateObjectLogging
} from '../endpoints';
import Box from '../../common/box/Box';
import Text from '../../common/text/Text';
import Title from '../../common/title/Title';
import Button from '../../common/button/Button';
import hasPermission from '../../../security/Permission';
import LightTooltip from '../../common/tooltip/Tooltip';
import ConfirmVersioning from '../confrim/ConfirmVersioning';
import VersioningSwitchContainer from './components/versioning-switch-container';
import Input, { ErrorMessage } from '../../common/input/Input';
import { DeleteBucketModal } from '../delete/DeleteBucketModal';
import { ConfirmComplianceMode } from '../confrim/ConfirmComplianceMode';
import { ButtonToggle } from '../../common/button/ButtonToggle';
import { ModalBody } from '../../common/modal/Modal';
import { colors } from '../../constant';
import { Dropdown } from '../../common/select/Dropdown';
import { LabelInput } from '../../common/label/LabelInput';
import { LoaderWrap } from '../../common/loader/Loader';
import { showToast } from '../../../hooks/show-toast';

const BucketSettings = () => {
  const { t } = useTranslation();
  const {
    register,
    setError,
    clearErrors,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({ mode: 'onChange' });
  const [settingsResponse, setSettingsResponse] = useState(null);
  const timeScaleDropDown = [
    {
      label: t('days'),
      value: 'Day(s)'
    },
    {
      label: t('years'),
      value: 'Year(s)'
    }
  ];
  const [response, setResponse] = useState(null);
  const enabledColor = '#CEFFDD';
  const [isLoggingToggled, setIsLoggingToggled] = useState(false);
  const [isLockingToggled, setIsLockingToggled] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [versioning, setVersioning] = useState(null);
  const [bucketToStoreLogs, setBucketToStoreLogs] = useState('');
  const [loggingPrefix, setLoggingPrefix] = useState('');
  const [displayLoader, setDisplayLoader] = useState(false);
  const [updateDisabled, setUpdateDisabled] = useState(true);
  const [lockingDisable, setLockingDisable] = useState(true);
  const [bucketData, setBucketData] = useState(null);
  const [bucketDataLogginPrefix, setBucketDataLogginPrefix] = useState(null);
  const [suspendVersioningModalOpen, setSuspendVersioningModalOpen] = useState(
    false
  );
  const [enableVersioningModalOpen, setEnableVersioningModalOpen] = useState(
    false
  );
  const [retentionTime, setRetentionTime] = useState(0);
  const [modalTitle, setModalTitle] = useState(0);
  const [retentionEnabled, setRetentionEnabled] = useState(false);
  const [
    bucketDataRetenitonTimeRetention,
    setBucketDataRetentionTimeRetention
  ] = useState(null);
  const [
    bucketDataRetenitonTimeScale,
    setBucketDataRetentionTimeScale
  ] = useState(null);
  const [bucketDataRetenitonMode, setBucketDataRetentionMode] = useState(null);
  const [checkLocked, setCheckLocked] = useState(false);
  const [mode, setMode] = useState('');
  const [modalText, setModalText] = useState('');
  const [checkLogged, setCheckLogged] = useState(false);
  const [timeScale, setTimeScale] = useState('');
  const { name } = useParams();
  const canReadBucketSettingsPerm = hasPermission(
    'oem_objects',
    'read_bucket_settings'
  );
  const canUpdateBucketSettingsPerm = hasPermission(
    'oem_objects',
    'update_bucket_settings'
  );
  const canDeleteBucketPerm = hasPermission('oem_buckets', 'delete');
  const [loggingActive, setLoggingActive] = useState(false);
  const [bucketStoreActive, setBucketStoreActive] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(null);
  const textTagColor = '#073A16';
  const complianceText = t('complianceText');
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const fetchBucketProperties = name => {
    setDisplayLoader(true);
    getAllBucketNames();
    getBucketProperties(name).then(([res]) => {
      setDisplayLoader(false);
      if (res.success) {
        checkLogging(res.data.Logging);
        checkLocking(res.data.Locking);
        if (res.data?.Retention?.mode === 'GOVERNANCE') {
          setMode('GOVERNANCE');
          setModalTitle(t('confirmGovernanceMode'));
          setModalText(t('governanceMode'));
        } else if (res.data?.Retention?.mode === 'COMPLIANCE') {
          setMode('COMPLIANCE');
          setModalTitle(t('confirmComplianceMode'));
          setModalText(t('complianceMode'));
        }
        setSettingsResponse(res.data);
        setBucketData(res.data?.Logging?.Prefix);
        setBucketDataLogginPrefix(res.data?.Logging?.TargetBucket);
        setBucketDataRetentionTimeRetention(res.data?.Retention?.retentionTime);
        setBucketDataRetentionTimeScale(res.data?.Retention?.timeScale);
        setBucketDataRetentionMode(res.data?.Retention?.mode);
        if (res.data.Versioning === 'Enabled') {
          setVersioning('Enabled');
        } else if (res.data.Versioning === 'Suspended') {
          setVersioning('Suspended');
        } else {
          setVersioning(res.data.Versioning);
        }
      } else {
        if (res?.code || res?.Code !== 'ErrCcc0045')
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: false
            },
            'error'
          );
      }
    });
  };

  const handleClick = () => {
    setLoggingActive(true);
  };

  const handleBucketToStoreLogs = () => {
    if (isLoggingToggled) {
      setIsMenuOpened(true);
      setBucketStoreActive(true);
    }
  };

  const checkLogging = resCheckLogged => {
    if (resCheckLogged) {
      setCheckLogged(true);
    } else {
      setCheckLogged(false);
    }
  };

  const checkErrMessageMax = timeScale => {
    let errMessMax = ''; // Define the variable locally
    if (timeScale === 'Year(s)') {
      errMessMax = t('errMessMaxYears', { count: 100 });
    } else if (timeScale === 'Day(s)') {
      errMessMax = t('errMessMaxDays', { count: 36500 });
    }
    return errMessMax;
  };

  checkErrMessageMax(timeScale);

  useEffect(() => {
    if (timeScale === 'Year(s)' && retentionTime > 100) {
      setError('retentionTime', {
        type: 'max',
        message: t('retentionTimeMaxYears', { count: 100 })
      });
    } else if (timeScale === 'Day(s)' && retentionTime > 36500) {
      setError('retentionTime', {
        type: 'max',
        message: t('retentionTimeMaxDays', { count: 36500 })
      });
    } else {
      clearErrors('retentionTime');
    }
  }, [timeScale, retentionTime]);

  const checkLocking = resCheckLocking => {
    if (resCheckLocking) {
      setCheckLocked(true);
    } else {
      setCheckLocked(false);
    }
  };

  useEffect(() => {
    fetchBucketProperties(name);
  }, []);

  useEffect(() => {
    if (settingsResponse) {
      getAllBucketNames();
      if (settingsResponse?.Retention.mode !== '') {
        setIsLockingToggled(true);
      }
      setRetentionTime(settingsResponse?.Retention.retentionTime ?? '');
      setTimeScale(settingsResponse?.Retention.timeScale);
      setMode(settingsResponse?.Retention.mode ?? '');
      setRetentionEnabled(settingsResponse?.Retention.enabled ?? false);
      setLoggingPrefix(settingsResponse?.Logging?.Prefix ?? '');
      setBucketToStoreLogs(settingsResponse?.Logging?.TargetBucket ?? '');
      setIsLoggingToggled(!!settingsResponse?.Logging ?? false);
      setValue('storeLogs', settingsResponse?.Logging?.TargetBucket ?? '');
      setValue('loggingPrefix', settingsResponse?.Logging?.Prefix ?? '');
      setValue(
        'retentionTime',
        settingsResponse?.Retention?.retentionTime ?? 0
      );
      setValue('mode', settingsResponse?.Retention?.mode ?? '');
      setValue('timeScale', settingsResponse?.Retention?.timeScale ?? '');
    }
  }, [settingsResponse]);

  const handleVersioningModal = version => {
    updateBucketVersioning(version);
    if (version === 'Suspended' && !settingsResponse?.Locking)
      setVersioning('Suspended');
    else setVersioning('Enabled');
  };

  const disableBucketLogging = () => {
    updateObjectLogging(name).then(([res]) => {
      setDisplayLoader(false);
      if (res.success) {
        setUpdateDisabled(true);
        setLoggingPrefix('');
        setBucketToStoreLogs('');
        setCheckLogged(false);
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
      } else {
        setIsLoggingToggled(true);
        if (res?.code || res?.Code !== 'ErrCcc0045')
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: res?.success
            },
            res?.success ? 'success' : 'error'
          );
      }
      setButtonLoading(false);
    });
  };
  const updateBucketVersioning = value => {
    enableBucketVersioning(name, value).then(([res]) => {
      if (res.success) {
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
        setSuspendVersioningModalOpen(false);
        setEnableVersioningModalOpen(false);
      } else {
        setSuspendVersioningModalOpen(false);
        if (res?.code || res?.Code !== 'ErrCcc0045')
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: res?.success
            },
            res?.success ? 'success' : 'error'
          );
      }
      setButtonLoading(false);
    });
  };

  const handleIsLoggingToggled = event => {
    setIsLoggingToggled(prev => !prev);
    if (!event && !checkLogged) {
      setLoggingPrefix('');
      setBucketToStoreLogs('');
      setValue('storeLogs', '');
      setLoggingActive(false);
      setBucketStoreActive(false);
      clearErrors('loggingPrefix');
    } else if (!event && checkLogged && loggingPrefix === '') {
      setLoggingActive(false);
      clearErrors('loggingPrefix');
    } else if (!event) {
      clearErrors('loggingPrefix');
    }
    if (checkLogged) {
      setUpdateDisabled(false);
    }
  };

  const handleIsLockingToggled = event => {
    setIsLockingToggled(prev => !prev);
    if (!event) {
      setRetentionTime(0);
      setTimeScale('');
      setMode('');
      setBucketDataRetentionMode('');
      if (retentionEnabled) {
        disableRetention();
        setMode('');
        setRetentionEnabled(false);
        setRetentionTime(0);
        setTimeScale('');
      }
    }
  };

  /**
   * Get all bucket names
   */
  const getAllBucketNames = () => {
    getAllBucketsNames().then(([res]) => {
      if (res.success) {
        setResponse(res?.data);
      } else {
        if (res?.code || res?.Code !== 'ErrCcc0045')
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: false
            },
            'error'
          );
      }
    });
  };

  /**
   * Delete bucket.
   */
  const [showDeleteBucket, setShowDeleteBucket] = useState(false);
  const onDeleteClick = useCallback(
    () => () => {
      setShowDeleteBucket(true);
    },
    []
  );

  const [showComplianceMode, setShowComplianceMode] = useState(false);
  const onUpdateClick = useCallback(
    () => () => {
      setShowComplianceMode(true);
    },
    []
  );

  const navigate = useNavigate();
  const handleDeleteBucket = props => {
    setDisplayLoader(true);
    setIsButtonLoading(true);
    deleteBucket(props?.name).then(([res]) => {
      setDisplayLoader(false);

      if (res.success) {
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
        setShowDeleteBucket(false);
        setTimeout(() => {
          const path = '/buckets';
          navigate(path);
        }, 2000);
      } else {
        if (res?.code || res?.Code !== 'ErrCcc0045')
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: false
            },
            'error'
          );
      }
      setIsButtonLoading(false);
    });
  };

  const onChangeLoggingPrefix = e => {
    setLoggingPrefix(e.target.value);
  };

  const handleChangeMode = mode => {
    if (mode === 'GOVERNANCE' || mode === '') {
      setMode('GOVERNANCE');
      setModalTitle(t('confirmGovernanceMode'));
      setModalText(t('governanceMode'));
    } else if (mode === 'COMPLIANCE') {
      setMode('COMPLIANCE');
      setModalTitle(t('confirmComplianceMode'));
      setModalText(t('complianceMode'));
    }
  };

  const onChangeRetentionTime = e => {
    const value = e.target.value.replace(/\s/g, ''); // remove all space characters
    const isValid = /^[0-9]*$/.test(value); // check if value only contains digits
    if (!isValid) {
      setError('retentionTime', { type: 'pattern', message: 'custom message' });
      return;
    }
    if (timeScale === 'Day(s)' && value > 36500) {
      setError('retentionTime', { type: 'pattern', message: 'custom message' });
    }
    if (timeScale === 'Year(s)' && value > 100) {
      setError('retentionTime', { type: 'pattern', message: 'custom message' });
    }
    if (value > 1000000) {
      return;
    }
    setRetentionTime(value === '' ? '' : parseInt(value));
  };

  const onChangeTimeScale = e => {
    setTimeScale(e.target.value);
    setRetentionTime(0);
  };

  const handleStoreLogsChange = event => {
    setBucketToStoreLogs(event.target.value);
  };

  const handleFormSubmit = () => {
    setButtonLoading('logging');
    if (!isLoggingToggled) {
      disableBucketLogging();
      setBucketToStoreLogs('');
      setLoggingActive(false);
      setBucketStoreActive(false);
      setBucketDataLogginPrefix('');
      setLoggingPrefix('');
      setValue('storeLogs', '');
      setValue('loggingPrefix', '');
    } else {
      setDisplayLoader(true);
      const submitData = {
        Enabled: true,
        Prefix: loggingPrefix,
        TargetBucket: bucketToStoreLogs
      };
      updateBucketProperties(name, submitData).then(([res]) => {
        setDisplayLoader(false);

        if (res.success) {
          setCheckLogged(true);
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: res?.success
            },
            res?.success ? 'success' : 'error'
          );
          setBucketData(res.data?.Prefix);
          setBucketDataLogginPrefix(res.data?.TargetBucket);
          setUpdateDisabled(true);
        } else {
          if (res?.code || res?.Code !== 'ErrCcc0045')
            showToast(
              {
                message: res?.code || res?.Code,
                params: res?.params || null,
                success: false
              },
              'error'
            );
        }
        setButtonLoading(null);
      });
    }
  };

  const handleFormSubmitLocking = () => {
    setButtonLoading('locking');
    const submitData = {
      Enabled: true,
      Mode: mode,
      TimeScale: timeScale,
      RetentionTime: retentionTime
    };

    enableObjectLocking(name, submitData).then(([res]) => {
      setDisplayLoader(false);
      setLockingDisable(true);
      if (res.success) {
        setRetentionEnabled(true);
        setBucketDataRetentionTimeRetention(res.data?.retentionTime);
        setBucketDataRetentionTimeScale(res.data?.timeScale);
        setBucketDataRetentionMode(res?.data?.mode);
        setValue('mode', res?.data?.mode);
        setTimeout(() => {
          showToast(
            {
              message: res?.code || res?.Code,
              params: res?.params || null,
              success: res?.success
            },
            res?.success ? 'success' : 'error'
          );
        }, 1000); // 1 second delay
        setShowComplianceMode(false);
      } else {
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
      }
      setButtonLoading(null);
    });
  };

  const disableRetention = () => {
    const submitData = {
      Enabled: false,
      Mode: '',
      TimeScale: timeScale,
      RetentionTime: retentionTime
    };
    enableObjectLocking(name, submitData).then(([res]) => {
      setDisplayLoader(false);
      if (res.success) {
        setLockingDisable(true);
        setBucketDataRetentionTimeRetention(0);
        setBucketDataRetentionTimeScale('');
        setBucketDataRetentionMode('');
        setValue('mode', '');
        setMode('');
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
      } else {
        showToast(
          {
            message: res?.code || res?.Code,
            params: res?.params || null,
            success: res?.success
          },
          res?.success ? 'success' : 'error'
        );
      }
    });
  };

  useEffect(() => {
    if (
      !errors.loggingPrefix &&
      loggingPrefix?.length > 0 &&
      getValues().storeLogs?.length > 0
    ) {
      if (
        bucketData === getValues().loggingPrefix &&
        bucketDataLogginPrefix === getValues().storeLogs &&
        !isLoggingToggled
      ) {
        setUpdateDisabled(false);
      } else if (
        bucketData === getValues().loggingPrefix &&
        bucketDataLogginPrefix === getValues().storeLogs &&
        isLoggingToggled
      ) {
        setUpdateDisabled(true);
        setLoggingActive(true);
        setBucketStoreActive(true);
      } else if (checkLogged && !isLoggingToggled) {
        setUpdateDisabled(false);
      } else if (
        (bucketData?.length > 0 ||
          bucketDataLogginPrefix != getValues().storeLogs) &&
        isLoggingToggled
      ) {
        setUpdateDisabled(false);
      } else {
        setUpdateDisabled(true);
      }
    } else if (
      getValues().loggingPrefix?.length === 0 &&
      checkLogged &&
      !isLoggingToggled
    ) {
      setUpdateDisabled(false);
    } else {
      setUpdateDisabled(true);
    }
  }, [getValues()]);

  useEffect(() => {
    if (
      !errors.retentionTime &&
      retentionTime > 0 &&
      timeScale?.length > 0 &&
      bucketDataRetenitonMode?.length > 0
    ) {
      if (
        bucketDataRetenitonMode === getValues().mode &&
        bucketDataRetenitonTimeScale === getValues().timeScale &&
        Number(bucketDataRetenitonTimeRetention) ===
          Number(getValues().retentionTime) &&
        Number(bucketDataRetenitonTimeRetention)
      ) {
        setLockingDisable(true);
      } else {
        setLockingDisable(false);
      }
    } else {
      setLockingDisable(true);
    }
  }, [getValues()]);

  const numberRegex = /^-?\d*\.?\d+$/;

  return (
    <>
      <ConfirmVersioning
        handleSubmit={() => {
          setButtonLoading(true);
          handleVersioningModal('Enabled');
        }}
        isLoading={buttonLoading}
        modalWidth={'400px'}
        title={t('enableVersioning')}
        close={() => setEnableVersioningModalOpen(false)}
        open={enableVersioningModalOpen}
        children={
          <div>
            <Box>
              <Text mb="20px" fontSize="14px" fontWeight="400">
                {t('versioningEnabledDescription')}
              </Text>
            </Box>
            <Box>
              <Text display="inline" mb="20px" fontSize="14px" fontWeight="400">
                {t('confirmEnableVersioning')}
              </Text>
            </Box>
          </div>
        }
      />
      <ConfirmVersioning
        handleSubmit={() => {
          setButtonLoading(true);
          handleVersioningModal('Suspended');
        }}
        isLoading={buttonLoading}
        modalWidth={'400px'}
        title={t('suspendVersioning')}
        close={() => setSuspendVersioningModalOpen(false)}
        open={suspendVersioningModalOpen}
        children={
          <div>
            <Box>
              <Text mb="20px" fontSize="14px" fontWeight="400">
                {t('suspendVersioningDescription')}
              </Text>
            </Box>
            <Box>
              <Text mb="20px" fontSize="14px" fontWeight="400">
                {t('confirmSuspendVersioning')}
              </Text>
            </Box>
          </div>
        }
      />
      {canReadBucketSettingsPerm && (
        <Box p="0 30px 30px 30px">
          {settingsResponse ? (
            <div>
              <Grid>
                <Box
                  bgcolor={colors.white}
                  p="30px"
                  borderradius="4px"
                  minheightsize="250px"
                >
                  <Box
                    display="flex"
                    alignitems="center"
                    justifycontent="space-between"
                    mb="20px"
                  >
                    <Title
                      fontSize="18px"
                      fontWeight="500"
                      font
                      color={colors.black}
                    >
                      {t('bucketVersioning')}
                    </Title>
                  </Box>
                  {versioning !== null && (
                    <VersioningSwitchContainer
                      title={
                        versioning === 'Suspended'
                          ? t('suspended')
                          : t('suspendVersioning')
                      }
                      description={t('suspendVersioningDescription')}
                      onChange={() => setSuspendVersioningModalOpen(true)}
                      disabled={!canUpdateBucketSettingsPerm || checkLocked}
                      checked={versioning === 'Suspended'}
                      name="versioning"
                      value="Suspended"
                      id="suspended"
                    />
                  )}

                  {versioning === null && (
                    <VersioningSwitchContainer
                      title={t('unversioned')}
                      description={t('unversionedDescription')}
                      onChange={() => setSuspendVersioningModalOpen(true)}
                      disabled={!canUpdateBucketSettingsPerm}
                      checked={versioning === null}
                      name="versioning"
                      readonly={true}
                      value="Suspended"
                      id="suspended"
                    />
                  )}
                  <VersioningSwitchContainer
                    title={
                      versioning === 'Enabled'
                        ? t('enabled')
                        : t('enableVersioning')
                    }
                    description={t('versioningDescription')}
                    onChange={() => setEnableVersioningModalOpen(true)}
                    disabled={!canUpdateBucketSettingsPerm}
                    checked={versioning === 'Enabled'}
                    name="versioning"
                    value="Enabled"
                    id="enabled"
                  />
                </Box>

                <Box
                  bgcolor={colors.white}
                  p="30px"
                  borderradius="4px"
                  minheightsize="300px"
                >
                  <Box
                    display="flex"
                    alignitems="center"
                    justifycontent="space-between"
                    mb="20px"
                  >
                    <Title
                      fontSize="18px"
                      fontWeight="500"
                      color={colors.black}
                    >
                      {t('bucketLogging')}
                    </Title>
                  </Box>
                  <Text fontSize="14px" maxwidth="420px" lineheight="18px">
                    {t('bucketLoggingDescription')}
                  </Text>
                  <Box
                    display="flex"
                    alignitems="center"
                    justifycontent="space-between"
                    mt="20px"
                    mb="20px"
                    gap="20px"
                  >
                    <Text fontSize="14px" fontWeight="500">
                      {t('enableBucketLogging')}
                    </Text>

                    <ButtonToggle
                      isToggled={isLoggingToggled}
                      handle={() => handleIsLoggingToggled(!isLoggingToggled)}
                      disabled={!canUpdateBucketSettingsPerm}
                    />
                  </Box>

                  <Box mt="30px">
                    <div className="input-wrapper">
                      <Input
                        size="0"
                        width="100%"
                        fontSize="14px"
                        id={'name'}
                        name="loggingPrefix"
                        value={loggingPrefix}
                        disabled={
                          !canUpdateBucketSettingsPerm || !isLoggingToggled
                        }
                        onClick={handleClick}
                        readOnly={
                          !canUpdateBucketSettingsPerm || !isLoggingToggled
                        }
                        className={errors.loggingPrefix ? 'not-valid' : ''}
                        {...register('loggingPrefix', {
                          onChange: onChangeLoggingPrefix.bind(this),
                          maxLength: 256,
                          required: isLoggingToggled,
                          pattern: /^\S.*$/gm
                        })}
                      />
                      <label
                        htmlFor="name"
                        style={{
                          zIndex: 3,
                          background: loggingActive ? 'white' : 'transparent'
                        }}
                        className={
                          loggingPrefix.length > 0 ? 'filledField' : ''
                        }
                      >
                        {t('loggingPrefix')}
                      </label>

                      {errors.loggingPrefix?.type === 'required' && (
                        <ErrorMessage>
                          {t('loggingPrefixRequired')}{' '}
                        </ErrorMessage>
                      )}
                      {errors.loggingPrefix?.type === 'maxLength' && (
                        <ErrorMessage>
                          {t('loggingPrefixMaxLength')}
                        </ErrorMessage>
                      )}
                      {errors.loggingPrefix?.type === 'pattern' && (
                        <ErrorMessage>
                          {t('loggingPrefixPattern')}{' '}
                        </ErrorMessage>
                      )}
                    </div>

                    <FormControl fullWidth>
                      <div className="input-wrapper">
                        <label
                          onClick={handleBucketToStoreLogs}
                          htmlFor="storeLogs"
                          style={{
                            zIndex: 3,
                            background: bucketStoreActive
                              ? 'white'
                              : 'transparent'
                          }}
                          className={
                            bucketToStoreLogs.length > 0 || isMenuOpened
                              ? 'filledField'
                              : ''
                          }
                        >
                          {t('bucketToStoreLogs')}
                        </label>
                        <Dropdown
                          OutlinedInput={true}
                          id="storeLogs"
                          label={t('bucketToStoreLogs')}
                          labelId="storeLogsLabel"
                          fullWidth
                          disabled={
                            !canUpdateBucketSettingsPerm || !isLoggingToggled
                          }
                          variant="outlined"
                          name="storeLogs"
                          disable={
                            !canUpdateBucketSettingsPerm || !isLoggingToggled
                          }
                          readOnly={
                            !canUpdateBucketSettingsPerm || !isLoggingToggled
                          }
                          value={bucketToStoreLogs}
                          {...register('storeLogs', {
                            onChange: handleStoreLogsChange,
                            required: true
                          })}
                          onOpen={() => setIsMenuOpened(true)}
                          onClose={() => setIsMenuOpened(false)}
                          open={isMenuOpened}
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left'
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left'
                            },
                            PaperProps: { sx: { maxHeight: 230 } }
                          }}
                        >
                          {response &&
                            response.map((b, index) => (
                              <MenuItem key={b} value={b}>
                                <LightTooltip
                                  title={b?.length > 43 ? b : null}
                                  placement="bottom-start"
                                >
                                  <Box
                                    maxwidth="340px"
                                    title={b}
                                    className="text-ellipsis"
                                    w="100%"
                                    p="0"
                                    lineheight="40px"
                                  >
                                    <Text
                                      className="text-ellipsis"
                                      fontSize="14px"
                                      fontWeight="400"
                                    >
                                      {b}
                                    </Text>
                                  </Box>
                                </LightTooltip>
                              </MenuItem>
                            ))}
                        </Dropdown>
                      </div>
                    </FormControl>

                    {canUpdateBucketSettingsPerm && (
                      <Box display="flex" justifycontent="flex-end" mt="30px">
                        <Button
                          w="136px"
                          isLoading={buttonLoading === 'logging'}
                          disabled={updateDisabled}
                          primary
                          onClick={
                            canUpdateBucketSettingsPerm
                              ? handleFormSubmit
                              : null
                          }
                        >
                          {t('update')}
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box
                  bgcolor={colors.white}
                  p="30px"
                  borderradius="4px"
                  minheightsize="300px"
                >
                  <Box
                    display="flex"
                    alignitems="center"
                    justifycontent="space-between"
                    mb="20px"
                  >
                    <Title
                      fontSize="18px"
                      fontWeight="500"
                      color={colors.black}
                    >
                      {t('objectLocking')} {/* Translation for title */}
                    </Title>
                    {false && (
                      <Text
                        color={textTagColor}
                        background={enabledColor}
                        fontSize="14px"
                        fontWeight="500"
                      >
                        {t('enabled')} {/* Translation for status */}
                      </Text>
                    )}
                  </Box>
                  {checkLocked && (
                    <Text fontSize="14px" maxwidth="440px" lineheight="18px">
                      {t('lockedDescription')}
                      {/* Translation for locked description */}
                    </Text>
                  )}
                  {!checkLocked && (
                    <Text fontSize="14px" maxwidth="440px" lineheight="18px">
                      {t('unlockedDescription')}
                      {/* Translation for unlocked description */}
                    </Text>
                  )}
                  {checkLocked && (
                    <Box
                      display="flex"
                      alignitems="center"
                      justifycontent="space-between"
                      mt="20px"
                      mb="15px"
                      gap="20px"
                    >
                      <Text fontSize="14px" fontWeight="500">
                        {t('enableBucketRetention')}
                      </Text>
                      <ButtonToggle
                        isToggled={isLockingToggled}
                        handle={() => handleIsLockingToggled(!isLockingToggled)}
                        disabled={!canUpdateBucketSettingsPerm}
                      />
                    </Box>
                  )}
                  {checkLocked && (
                    <Text fontSize="14px" maxwidth="440px" lineheight="18px">
                      {t('autoApplySettings')}
                    </Text>
                  )}

                  <Box display={isLockingToggled ? 'block' : 'none'} mt="30px">
                    <VersioningSwitchContainer
                      title={
                        getValues().mode === 'GOVERNANCE'
                          ? t('governanceMode')
                          : t('enableGovernanceMode')
                      }
                      disabled={!canUpdateBucketSettingsPerm}
                      description={t('governanceModeDescription')}
                      onChange={() => {
                        handleChangeMode('GOVERNANCE');
                        setBucketDataRetentionMode('GOVERNANCE');
                      }}
                      checked={mode === 'GOVERNANCE'}
                      name="mode"
                      value="governance"
                      id="governance"
                      currentDatabaseMode={getValues().mode === 'GOVERNANCE'}
                    />
                    <VersioningSwitchContainer
                      title={
                        getValues().mode === 'COMPLIANCE'
                          ? t('complianceMode')
                          : t('enableComplianceMode')
                      }
                      disabled={!canUpdateBucketSettingsPerm}
                      description={t('complianceModeDescription')}
                      onChange={() => {
                        handleChangeMode('COMPLIANCE');
                        setBucketDataRetentionMode('COMPLIANCE');
                      }}
                      name="mode"
                      checked={mode === 'COMPLIANCE'}
                      value="compliance"
                      id="compliance"
                      currentDatabaseMode={getValues().mode === 'COMPLIANCE'}
                    />
                    <ModalBody>
                      <>
                        <Box
                          display="flex"
                          mt="30px"
                          alignitems="center"
                          justifycontent="flex-start"
                          gap="20px"
                        >
                          <FormControl mt="40px" sx={{ width: '188px' }}>
                            <LabelInput id="timeScale" htmlFor="timeScale">
                              {t('timeScale')}*
                            </LabelInput>
                            <Dropdown
                              id="time scale"
                              label={t('timeScale')}
                              labelId="timeScale"
                              disabled={!canUpdateBucketSettingsPerm}
                              variant="outlined"
                              name="timeScale"
                              value={timeScale}
                              {...register('timeScale', {
                                onChange: onChangeTimeScale,
                                required: true
                              })}
                              MenuProps={{
                                getcontentanchorel: null,
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left'
                                },
                                transformOrigin: {
                                  vertical: 'top',
                                  horizontal: 'left'
                                },
                                PaperProps: { sx: { maxHeight: 230 } }
                              }}
                            >
                              {timeScaleDropDown &&
                                timeScaleDropDown.map(b => (
                                  <MenuItem key={b?.value} value={b?.value}>
                                    <Box
                                      maxwidth="340px"
                                      title={b?.value}
                                      className="text-ellipsis"
                                      w="100%"
                                      p="0"
                                      lineheight="40px"
                                    >
                                      <Text fontSize="14px" fontWeight="400">
                                        {b?.label}
                                      </Text>
                                    </Box>
                                  </MenuItem>
                                ))}
                            </Dropdown>
                          </FormControl>

                          <div className="input-wrapper">
                            <FormControl mt="40px" sx={{ width: '188px' }}>
                              <Input
                                size="0"
                                marginBottom="0px"
                                width="100%"
                                fontSize="14px"
                                pattern="[1-9]"
                                name="retentionTime"
                                max={100000}
                                value={retentionTime > 0 ? retentionTime : ''}
                                disabled={!canUpdateBucketSettingsPerm}
                                className={
                                  errors.retentionTime ? 'not-valid' : ''
                                }
                                {...register('retentionTime', {
                                  onChange: onChangeRetentionTime.bind(this),
                                  required: true,
                                  pattern: {
                                    value: numberRegex
                                  },
                                  max: timeScale === 'Year(s)' ? 100 : 36500
                                })}
                              />
                              <label
                                htmlFor="name"
                                className={
                                  retentionTime > 0 ? 'filledField' : ''
                                }
                              >
                                {t('retentionTime')}*
                              </label>
                              {errors.retentionTime?.type === 'required' && (
                                <ErrorMessage marginTop="30px">
                                  {t('retentionTimeRequired')}
                                </ErrorMessage>
                              )}
                              {errors.retentionTime?.type === 'max' && (
                                <ErrorMessage marginTop="30px">
                                  {errors?.retentionTime?.message?.length > 0
                                    ? errors?.retentionTime?.message
                                    : timeScale === 'Year(s)'
                                    ? t('errMessMaxYears', { count: 100 })
                                    : t('errMessMaxDays', { count: 36500 })}
                                </ErrorMessage>
                              )}
                              {errors.retentionTime?.type === 'pattern' && (
                                <ErrorMessage marginTop="30px">
                                  {t('retentionTimePattern')}
                                </ErrorMessage>
                              )}
                            </FormControl>
                          </div>
                        </Box>
                      </>
                    </ModalBody>

                    {canUpdateBucketSettingsPerm && (
                      <Box display="flex" justifycontent="flex-end" mt="30px">
                        <Button
                          disabled={lockingDisable}
                          primary
                          onClick={
                            canUpdateBucketSettingsPerm ? onUpdateClick() : null
                          }
                        >
                          {t('update')}
                        </Button>
                      </Box>
                    )}

                    <ConfirmComplianceMode
                      isLoading={buttonLoading === 'locking'}
                      open={showComplianceMode}
                      close={() => setShowComplianceMode(false)}
                      title={modalTitle}
                      sx={{ color: colors.primary }}
                      text={modalText}
                      complainceText={
                        mode === 'COMPLIANCE' ? complianceText : ''
                      }
                      retentionTime={retentionTime}
                      timeScale={timeScale}
                      onSubmit={() => handleFormSubmitLocking()}
                    />
                  </Box>
                </Box>
              </Grid>
              {canDeleteBucketPerm && (
                <Box
                  bgcolor={colors.white}
                  p="10px 30px"
                  borderradius="4px"
                  display="flex"
                  alignitems="center"
                  justifycontent="space-between"
                  mt="30px"
                >
                  <Title fontSize="18px" fontWeight="500" font color="#0B293A">
                    {t('deleteBucket')}
                  </Title>
                  <Button
                    bgcolor={colors.softGray}
                    color={colors.gray20}
                    bordercolor="transparent"
                    className="delete"
                    onClick={canDeleteBucketPerm ? onDeleteClick(name) : null}
                  >
                    {t('delete')}
                  </Button>
                </Box>
              )}
              <DeleteBucketModal
                isLoading={isButtonLoading}
                open={showDeleteBucket}
                close={() => setShowDeleteBucket(false)}
                name={name}
                onSubmit={e => handleDeleteBucket(e)}
              />
            </div>
          ) : (
            <Box relative="true" bgcolor={colors.white} p="50px">
              <LoaderWrap display={displayLoader} />
            </Box>
          )}
        </Box>
      )}
      {localStorage.getItem('refreshTokenExpired') &&
      !canReadBucketSettingsPerm ? (
        <LoaderWrap display={true} />
      ) : (
        <>
          {!canReadBucketSettingsPerm && <Box>{t('noPermissionForRoute')}</Box>}
        </>
      )}
    </>
  );
};
const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 30px;
`;

export default BucketSettings;
