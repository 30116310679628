import styled from 'styled-components';

export const AccessKeyActionWrapper = styled.div`
  width: ${props => props?.wrapperWidth - 20}px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => (props?.isDropdown || props?.danger ? '40px' : '55px')};
  background: white;
  border: ${props => (props?.isDropdown ? ' 1px solid #F1F1F1;' : '')};
  padding: 2px 9px;
`;
export const AccessKeyActionContainer = styled.div`
  gap: 11px;
  display: flex;
  width: ${props => (props?.isDropdown ? '100%' : '100%')};
  height: ${props => (props?.isDropdown || props?.danger ? '38px' : '40px')};
  padding-left: ${props => (!props?.isDropdown ? '4px' : '0')};
  align-items: center;
  cursor: ${props => (props?.disabled ? 'not-allowed' : 'pointer')};
  background: white;
  &:hover {
    background-color: ${props => (!props?.isDropdown ? '#F5F6F8' : 'white')};
    border-radius: ${props => (!props?.isDropdown ? '4px' : '0')};
  }
`;

export const AccessKeyActionTitle = styled.h1`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${props =>
    props?.disabled ? '#D9DFE6;' : props.danger ? '#EB4343;' : '#6c757d;'};
  cursor: ${props => (props?.disabled ? 'not-allowed' : 'pointer')};
  white-space: nowrap;
`;

export const IconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: rotate(${props => (props?.rotate ? '90deg' : '-90deg')});
  transition: all 0.3s ease-out;
`;
